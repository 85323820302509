<template>
  <div>
    <a-drawer
      :title="showType === 'detail' ? '任务信息' : '生成结果'"
      placement="right"
      :width="450"
      :visible="showDrawer"
      @close="handleClose"
    >
      <a-spin :spinning="spin">
        <!--任务信息-->
        <template v-if="showType === 'detail'">
          <div class="drawer_title" v-show="!showEdit">
            {{ detailData.aiTaskName }}
            <span style="margin-left: 15px"
              ><a-icon style="color: #1890ff; cursor: pointer" type="edit" @click="handleTaskNameEdit"
            /></span>
          </div>
          <a-input v-show="showEdit" v-model="tempTaskName" placeholder="请输入任务名称">
            <a-icon slot="addonAfter" type="close" @click="handleEditCancel" />
            <a-icon slot="addonAfter" type="check" style="margin-left: 20px" @click="handleEditDone"
          /></a-input>
          <div style="font-size: 12px; color: #aaaaaa">任务ID：{{ detailData.id }}</div>
          <div class="drawer_title">详情</div>
          <div
            class="drawer_item"
            v-show="detailData.submitExternalTaskBody.principalName || detailData.submitExternalTaskBody.carSeriesName"
          >
            <span class="item_title" v-show="detailData.submitExternalTaskBody.principalName"
              >品牌：{{ detailData.submitExternalTaskBody.principalName }}</span
            >
            <span class="item_title" style="margin-left: 15px" v-show="detailData.submitExternalTaskBody.carSeriesName"
              >车系：{{ detailData.submitExternalTaskBody.carSeriesName }}</span
            >
          </div>
          <div class="drawer_item">
            <span class="item_title">视频比例：{{ detailData.submitExternalTaskBody.videoAspect }}</span>
          </div>
          <div class="drawer_item">
            <span class="item_title">成品数量：{{ detailData.submitExternalTaskBody.videoCount }}</span>
          </div>
          <div class="drawer_item">
            <span class="item_title" style="white-space: pre-wrap"
              >文案：{{ detailData.submitExternalTaskBody.videoScript }}</span
            >
          </div>
          <div class="drawer_item" v-show="detailData.submitExternalTaskBody.voiceName">
            <span class="item_title">配音：{{ detailData.submitExternalTaskBody.voiceName }}</span>
          </div>
          <div class="drawer_item" v-show="detailData.submitExternalTaskBody.bgmName">
            <span class="item_title">音乐：{{ detailData.submitExternalTaskBody.bgmName }}</span>
          </div>
          <div class="drawer_item" v-show="detailData.submitExternalTaskBody.subtitleEnabled">
            <span class="item_title">字幕：{{ detailData.submitExternalTaskBody.fontName || '-' }}</span>
          </div>
          <template v-if="detailData.aiTaskStatus === 'FAILED'">
            <div class="drawer_title">失败信息</div>
            <div class="drawer_item">
              <span class="item_title">报错信息：{{ detailData.failedMsg }}</span>
            </div>
          </template>
        </template>
        <!--生成结果-->
        <template v-if="showType === 'result'">
          <div class="result_title">
            <span class="drawer_title" style="margin-bottom: 0">生成结果</span>
            <a-button icon="sync" :loading="repeatLoading" @click="handleRepeat">再次生成</a-button>
          </div>
          <div class="video_line">
            <div class="video_item">
              <div
                class="video_cover"
                :style="{ backgroundImage: `url(${resultData.cover})`, cursor: 'not-allowed' }"
              ></div>
              <div class="video_mask" v-show="resultData.cover" @click="handlePreview">
                <div><a-icon type="eye" style="font-size: 28px" /></div>
                <div>查看视频</div>
              </div>
              <div class="video_duration" v-show="resultData.duration">{{ resultData.duration }}</div>
              <div class="video_size" v-show="resultData.fileSize">{{ resultData.fileSize }}</div>
            </div>
          </div>
          <!-- <div class="drawer_title">匹配素材</div>
          <div class="drawer_item">
            <span class="item_title">视频：</span>
            <span class="img_items" v-for="(item, index) in resultData.videoList" :key="index">
              <img :src="item.cover" alt="cover" />
            </span>
          </div>
          <div class="drawer_item">
            <span class="item_title">图片：</span>
            <span class="img_items" v-for="(item, index) in resultData.imageList" :key="index">
              <img :src="item.cover" alt="cover" />
            </span>
          </div>
          <div class="drawer_item">
            <span class="item_title"
              >配音：
              <audio style="width: 100%" controls src="http://music.163.com/song/media/outer/url?id=29723028.mp3"></audio>
            </span>
          </div>
          <div class="drawer_item">
            <span class="item_title"
              >音乐：
              <audio style="width: 100%" controls src="http://music.163.com/song/media/outer/url?id=29723028.mp3"></audio>
            </span>
          </div> -->
        </template>
      </a-spin>
      <div
        v-show="showType === 'result'"
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          zIndex: 1,
        }"
      >
        <a-space>
          <!-- <a-button type="primary">下载全部匹配素材</a-button> -->
          <a-button
            type="primary"
            :disabled="!resultData.videoUrl"
            :loading="downloadLoading"
            @click="handleDownloadVideo"
            >下载成片</a-button
          >
        </a-space>
      </div>
    </a-drawer>
    <preview-modal
      :visible="showPreview"
      :preview-src="previewUrl"
      preview-type="video"
      @cancel="handlePreviewCancel"
    />
  </div>
</template>

<script>
import api from '@/api/xhsAgencyApi';
import previewModal from '@/components/PreviewModalVideo/PreviewModalVideo';
import _base_https from '@/api/base_api';

export default {
  name: 'VideoDetailDrawer',
  props: {},
  components: { previewModal },
  data() {
    return {
      showDrawer: false,
      spin: false,
      taskId: undefined,
      showType: 'result', // 'detail' 'result',
      showEdit: false,
      tempTaskName: '',
      detailData: {
        submitExternalTaskBody: {},
      },
      resultData: {
        cover: undefined,
        videoUrl: undefined,
        duration: undefined,
        fileSize: undefined,
      },
      showPreview: false,
      previewUrl: '',
      repeatLoading: false,
      downloadLoading: false,
    };
  },
  methods: {
    handleOpen(taskId, type) {
      this.showDrawer = true;
      this.taskId = taskId;
      this.showType = type;
      type === 'detail' ? this.getDetailData() : this.getResultData();
      this.resultData = this.$options.data().resultData;
      this.detailData = this.$options.data().detailData;
    },
    async getDetailData() {
      this.spin = true;
      const { code, data, message } = await api.getAiVideoTaskDetail(this.taskId).finally(() => (this.spin = false));
      if (code === 200) {
        this.detailData = { ...data };
        console.log(data);
      } else {
        this.$message.error(message);
      }
    },
    async getResultData() {
      this.spin = true;
      const { code, data, message } = await api.getAiVideoUrl(this.taskId).finally(() => (this.spin = false));
      if (code === 200) {
        this.resultData.videoUrl = data.url;
        this.resultData.cover = data.coverUrl;
        this.resultData.duration = data.duration ? this.transSecToMin(data.duration) : undefined;
        this.resultData.fileSize = data.fileSize
          ? data.fileSize > 1
            ? `${data.fileSize.toFixed(2)}M`
            : `${(data.fileSize * 1024).toFixed(2)}K`
          : undefined;
      } else {
        this.$message.error(message);
      }
    },
    handleClose() {
      this.showDrawer = false;
      this.showEdit = false;
      this.downloadLoading = false;
      this.tempTaskName = '';
    },
    handleTaskNameEdit() {
      this.showEdit = true;
      this.tempTaskName = this.detailData.aiTaskName;
    },
    async handleEditDone() {
      const { code, message } = await api.updateVideoTaskName({
        aiTaskName: this.tempTaskName,
        id: this.taskId,
      });
      if (code === 200) {
        this.$message.success('修改成功');
        this.detailData.aiTaskName = this.tempTaskName;
        this.handleEditCancel();
        this.$emit('refresh');
      } else {
        this.$message.error(`修改失败：${message}`);
      }
    },
    handleEditCancel() {
      this.showEdit = false;
    },
    handlePreviewCancel() {
      this.showPreview = false;
      this.previewUrl = undefined;
    },
    handlePreview() {
      this.previewUrl = this.resultData.videoUrl;
      this.showPreview = true;
    },
    async handleRepeat() {
      this.repeatLoading = true;
      const { code, message } = await api.repeatVideoTask(this.taskId).finally(() => (this.repeatLoading = false));
      if (code === 200) {
        this.$message.success('操作成功');
        this.handleClose();
        this.$emit('refresh');
      } else {
        this.$message.error(`操作失败：${message}`);
      }
    },
    async handleDownloadVideo() {
      let link = this.resultData.videoUrl;
      if (!link) return this.$message.error('视频地址不存在');
      this.downloadLoading = true;
      this.$message.info('视频下载中，请稍后');
      const fileName = 'video' + Date.now();
      let x = new XMLHttpRequest();
      x.open('GET', link, true);
      x.responseType = 'blob';
      x.send();
      x.onload = () => {
        let url = window.URL.createObjectURL(x.response);
        let a = document.createElement('a');
        a.style.display = 'none';
        document.body.appendChild(a);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        this.downloadLoading = false;
      };
      x.error = () => {
        this.downloadLoading = false;
        this.$message.error('视频下载失败');
      };
    },
    transSecToMin(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    },
  },
};
</script>

<style lang="less" scoped>
.drawer_title {
  font-size: 18px;
  font-weight: bolder;
  color: #333333;
  margin: 10px 0;

  &:first-child {
    margin-top: 0;
  }
}

.drawer_item {
  margin-bottom: 5px;
  padding: 10px 15px;
  background-color: #f2f2f2;

  .item_title {
    font-size: 14px;
    color: #666666;
    margin-right: 10px;
  }
}

.video_line {
  margin-bottom: 20px;

  .video_item {
    width: 150px;
    height: 150px;
    position: relative;
    background-color: #cccccc;

    &:hover .video_mask {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .video_cover {
      margin: auto;
      width: 100%;
      height: 100%;
      position: relative;
      background-size: cover;
      background-position: center center;
      // img {
      //   width: 100%;
      //   height: 100%;
      // }
    }

    .video_duration {
      position: absolute;
      bottom: 0;
      left: 0;
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 2px 5px;
    }

    .video_size {
      position: absolute;
      bottom: 0;
      right: 0;
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 2px 5px;
    }

    .video_mask {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #fff;
      cursor: pointer;
      background-color: #00000080;
      user-select: none;
      z-index: 1;
    }
  }
}

.result_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.img_items {
  display: inline-block;
  margin-right: 10px;
  width: 70px;
  height: 70px;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
