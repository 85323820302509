<template>
  <div class="video_tab">
    <a-row :gutter="[20, 10]" style="margin-bottom: 20px">
      <a-col :span="4">
        <a-input-search
          placeholder="任务ID"
          v-model.trim="searchForm.taskId"
          allowClear
          @search="getDataList(true)"
        ></a-input-search>
      </a-col>
      <a-col :span="4">
        <a-select
          v-model="searchForm.aiTaskStatusList"
          placeholder="状态"
          style="width: 100%"
          allowClear
          @change="getDataList(true)"
        >
          <a-select-option v-for="item in statusList" :key="item.value" :value="item.value">{{
            item.label
          }}</a-select-option>
        </a-select>
      </a-col>

      <a-col :span="4">
        <a-select
          v-model="searchForm.creator"
          placeholder="创建人"
          style="width: 100%"
          allowClear
          @change="getDataList(true)"
        >
          <a-select-option v-for="(item, index) in videoCreatorList" :key="index" :value="item.username">{{
            item.name
          }}</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-table
      :columns="columns"
      row-key="id"
      :pagination="false"
      :loading="tableLoading"
      :data-source="dataList"
      @change="onTableChange"
    >
      <div slot="taskId" slot-scope="text, record">
        <div style="color: #000">
          {{ record.aiTaskName }}
        </div>
        <div style="color: #7f7f7f">ID: {{ record.id }}</div>
      </div>
      <div slot="taskType" slot-scope="text, record">
        {{ getSubjectType(record.taskType) }}
      </div>
      <div slot="AiStatus" slot-scope="text, record">
        <a-tag color="green" v-if="record.aiTaskStatus === 'SUCCESS'">成功</a-tag>
        <a-tag color="red" v-else-if="record.aiTaskStatus === 'FAILED'">失败</a-tag>
        <a-tag color="blue" v-else-if="record.aiTaskStatus === 'RUNNING'">进行中</a-tag>
        <a-tag color="geekblue" v-else-if="record.aiTaskStatus === 'IN_THE_LINE'">排队中</a-tag>
        <a-tag color="cyan" v-else>其他</a-tag>
      </div>
      <div slot="operation" slot-scope="text, record">
        <a-space :size="1">
          <a-button type="link" size="small" @click="getVideoTaskDetail(record.id, 'detail')">任务信息</a-button>
          <template v-if="record.aiTaskStatus === 'SUCCESS'">
            <a-button type="link" size="small" @click="getVideoTaskDetail(record.id, 'result')">生成结果</a-button>
            <!-- <a-button type="link" size="small">视频脚本</a-button> -->
          </template>
        </a-space>
      </div>
    </a-table>
    <base-pagination
      :currentPage="pagination.current"
      :options="['30', '50', '100']"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />

    <VideoDetailDrawer ref="VideoDetailDrawer" @refresh="getDataList()" />
  </div>
</template>

<script>
import api from '@/api/xhsAgencyApi';

import VideoDetailDrawer from './VideoDetailDrawer.vue';
export default {
  name: '',
  props: {},
  components: {
    VideoDetailDrawer,
  },
  data() {
    const statusList = [
      { label: '进行中', value: 'RUNNING' },
      { label: '成功', value: 'SUCCESS' },
      { label: '失败', value: 'FAILED' },
    ];
    const processClassList = [
      { label: '文生图', value: 'TEXT2IMAGE_CAR' },
      { label: '图生图-换背景', value: 'CAR_REPLACE_BACKGROUND' },
      { label: '图生图-换模特', value: 'REPLACE_MODEL' },
      { label: '车辆信息还原', value: 'VEHICLE_INFORMATION_RESTORATION' },
      { label: 'Ai改图', value: 'CAR_IMG_BY_IMG' },
    ];
    return {
      processClassList,
      statusList,
      columns: [
        {
          title: '任务名称',
          width: 200,
          scopedSlots: { customRender: 'taskId' },
        },
        {
          title: '状态',
          key: 'AiStatus',
          width: 100,
          scopedSlots: { customRender: 'AiStatus' },
        },
        {
          title: '创建时间',
          dataIndex: 'ctime',
          width: 150,
          sorter: true,
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          title: '完成时间',
          dataIndex: 'taskExecuteEndTime',
          width: 150,
          sorter: true,
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          title: '创建人',
          dataIndex: 'creator',
          width: 100,
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          title: '操作',
          width: 220,
          scopedSlots: { customRender: 'operation' },
        },
      ],
      searchForm: {},
      pagination: {
        current: 1,
        pageSize: 30,
        total: 1,
      },
      dataList: [],
      tableLoading: false,
      videoCreatorList: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.pageInit();
  },
  mounted() {},
  methods: {
    pageInit() {
      this.searchForm.creator = this.$router.app.user.username || undefined;
      this.getVideoCreatorList();
      this.getDataList(true);
    },
    getDataList(init = false) {
      if (init) this.pagination.current = 1;
      const { current: page, pageSize: size } = this.pagination;
      this.tableLoading = true;
      let params = {  aiTaskType: 'VIDEO' }; //'SUCCESS,FAILED,RUNNING'

      const getParams = {
        ...this.searchForm,
        ...params,
        page,
        size,
      };
      api
        .getAiVideoTaskLogList(getParams)
        .then((res) => {
          if (res.code === 200) {
            this.dataList = res.data.list;
            this.pagination.total = res.data.total;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    async getVideoCreatorList() {
      const res = await api.getAiVideoTaskCreatorList();
      if (res.code === 200) {
        this.videoCreatorList = res.data;
      }
    },
    getVideoTaskDetail(taskId, type) {
      this.$refs.VideoDetailDrawer && this.$refs.VideoDetailDrawer.handleOpen(taskId, type);
    },
    onTableChange(pagination, filters, sorter) {
      if (sorter.order) {
        this.searchForm.orderField = sorter.columnKey;
        this.searchForm.isAsc = sorter.order == 'ascend' ? true : false;
      } else {
        this.searchForm.orderField = undefined;
        this.searchForm.isAsc = undefined;
      }
      this.getDataList();
    },
    getSubjectType(type) {
      const returnName = this.processClassList.find((item) => item.value === type);
      return returnName ? returnName.label : '-';
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
  },
};
</script>

<style lang="less" scoped></style>
