<template>
  <div>
    <a-tabs v-model="tabKey" size="large" @change="handleTabChange">
      <a-tab-pane :key="1" tab="AI作图"></a-tab-pane>
      <a-tab-pane :key="2" tab="AI作视频"></a-tab-pane>
    </a-tabs>
    <div v-show="tabKey === 1">
      <a-row :gutter="[20, 10]" style="margin-bottom: 20px">
        <a-col :span="4">
          <a-input-search
            placeholder="任务ID"
            v-model.trim="searchForm.id"
            allowClear
            @search="getDataList(true)"
          ></a-input-search>
        </a-col>
        <a-col :span="4">
          <a-select
            v-model="searchForm.taskStatusList"
            placeholder="状态"
            style="width: 100%"
            allowClear
            @change="getDataList(true)"
          >
            <a-select-option v-for="item in statusList" :key="item.value" :value="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="4">
          <a-select
            v-model="searchForm.taskTypeList"
            placeholder="流程分类"
            style="width: 100%"
            allowClear
            @change="getDataList(true)"
          >
            <a-select-option v-for="item in processClassList" :key="item.value" :value="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="4">
          <a-select
            v-model="searchForm.creator"
            placeholder="创建人"
            style="width: 100%"
            allowClear
            @change="getDataList(true)"
          >
            <a-select-option v-for="(item, index) in picCreatorList" :key="index" :value="item.username">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="4">
          <a-input-search
            placeholder="任务名称"
            v-model.trim="searchForm.taskName"
            allowClear
            @search="getDataList(true)"
          ></a-input-search>
        </a-col>
      </a-row>
      <a-table :columns="columns" row-key="id" :pagination="false" :loading="tableLoading" :data-source="dataList">
        <div slot="taskId" slot-scope="text, record">
          <div style="color: #000">
            {{ record.taskName }}
          </div>
          <div style="color: #7f7f7f">ID: {{ record.id }}</div>
        </div>
        <div slot="taskType" slot-scope="text, record">
          {{ getSubjectType(record.taskType) }}
        </div>
        <div slot="taskStatus" slot-scope="text, record">
          <a-tag color="green" v-if="record.taskStatus === 'SUCCESS'">成功</a-tag>
          <a-tag color="red" v-else-if="record.taskStatus === 'FAILED'">失败</a-tag>
          <a-tag color="blue" v-else-if="record.taskStatus === 'RUNNING'">进行中</a-tag>
          <a-tag color="blue" v-else-if="record.taskStatus === 'IN_THE_LINE'">排队中</a-tag>
          <a-tag color="cyan" v-else>其他</a-tag>
        </div>
        <div slot="operation" slot-scope="text, record">
          <a-space :size="1">
            <a-button type="link" size="small" @click="getImgTaskDetail(record.id)">任务信息</a-button>
            <template v-if="record.taskStatus === 'SUCCESS'">
              <a-button type="link" size="small" @click="getParamsDetail(record.id)">输出参数</a-button>
              <a-button type="link" size="small" @click="checkTaskImg(record.id)">查看出图</a-button>
            </template>
          </a-space>
        </div>
      </a-table>
      <base-pagination
        :currentPage="pagination.current"
        :options="['30', '50', '100']"
        :pageSize="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </div>
    <template v-if="tabKey === 2">
      <VideoTab />
    </template>

    <a-drawer
      title="任务信息"
      placement="right"
      class="drawer_class"
      :width="450"
      :visible="taskVisible"
      @close="taskVisible = false"
    >
      <a-spin :spinning="searchLoading">
        <a-form-model ref="taskForm" :model="taskForm" :labelCol="{ xl: 6, xxl: 5 }" :wrapperCol="{ span: 17 }">
          <a-form-model-item label="任务名称" prop="taskName">
            <a-input v-model="taskForm.taskName" disabled placeholder="请填写任务名称" />
          </a-form-model-item>
          <a-form-model-item label="创建时间" prop="ctime">
            <a-date-picker show-time placeholder="请选择时间" disabled :value="taskForm.ctime" />
            <!-- <a-input v-model="taskForm.ctime" placeholder="请填写创建时间" /> -->
          </a-form-model-item>
          <a-form-model-item label="创建人" prop="creator">
            <a-input v-model="taskForm.creator" placeholder="请填写创建人" disabled />
          </a-form-model-item>
          <a-form-model-item label="任务ID" prop="id">
            <a-input v-model="taskForm.id" disabled />
          </a-form-model-item>
          <a-form-model-item
            :label="taskForm.subjectType == 'TEXT2IMAGE_CAR' ? '主题图' : '原图'"
            ref="sourceImage"
            prop="sourceImage"
          >
            <div style="pointer-events: none">
              <vod-base-upload
                :accept-list="'.png,.jpg,.jpeg'"
                :has-file-list="sourceImgList"
                :params-data="null"
                :client-id="'bfz-admin'"
                :get-width="true"
                :maxW_H="'768*1024'"
                showMaxErrorMsg
                :limit-size="3"
              />
            </div>
          </a-form-model-item>
          <a-form-model-item label="主题" prop="subjectId">
            <a-input disabled v-model="taskForm.subjectTitle"></a-input>
          </a-form-model-item>
          <a-form-model-item label="提示词" prop="carLoraPrompt">
            <a-textarea
              v-model="taskForm.carLoraPrompt"
              placeholder="请填写提示词"
              :auto-size="{ minRows: 3, maxRows: 7 }"
              disabled
            />
          </a-form-model-item>
          <a-form-model-item label="成片数量" prop="batchSize">
            <a-input-number
              v-model="taskForm.batchSize"
              allow-clear
              placeholder="成片数量"
              :min="1"
              :max="4"
              style="width: 100%"
              disabled
            />
          </a-form-model-item>
          <a-form-model-item label="车系" prop="carSeriesName">
            <a-input v-model="taskForm.carSeriesName" disabled />
          </a-form-model-item>
          <a-form-model-item label="车身颜色" prop="carColorKey">
            <a-input v-model="taskForm.carColorKey" disabled />
          </a-form-model-item>
          <a-form-model-item label="车身角度" prop="angleText">
            <a-input v-model="taskForm.angleText" disabled />
          </a-form-model-item>
          <a-form-model-item v-if="taskForm.taskStatus == 'FAILED'">
            <template slot="label"><span style="color: red">失败原因</span></template>
            {{ taskForm.failedMsg }}
          </a-form-model-item>
        </a-form-model>
      </a-spin>
    </a-drawer>

    <a-drawer title="输出参数" placement="right" :width="450" :visible="paramsVisible" @close="paramsVisible = false">
      <a-spin :spinning="searchLoading">
        <JsonViewer :value="paramsDetail" :expanded="true" />
      </a-spin>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          zIndex: 1,
        }"
      >
        <a-button type="primary" @click="downloadJson(paramsDetail)">下载JSON</a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import vodBaseUpload from '@/components/VodBaseUpload/vodBaseUpload';
import VideoTab from './components/VideoTab';
import api from '@/api/xhsAgencyApi';

export default {
  name: 'AiDrawLog',
  components: {
    vodBaseUpload,
    VideoTab,
  },
  data() {
    const statusList = [
      { label: '进行中', value: 'RUNNING' },
      { label: '成功', value: 'SUCCESS' },
      { label: '失败', value: 'FAILED' },
    ];
    const processClassList = [
      { label: '文生图', value: 'TEXT2IMAGE_CAR' },
      { label: '图生图-换背景', value: 'CAR_REPLACE_BACKGROUND' },
      { label: '图生图-换模特', value: 'REPLACE_MODEL' },
      { label: '车辆信息还原', value: 'VEHICLE_INFORMATION_RESTORATION' },
      { label: 'Ai改图', value: 'CAR_IMG_BY_IMG' },
    ];
    return {
      angleList: [
        {
          value: 'te xie',
          text: '特写',
        },
        {
          value: 'from back',
          text: '车背面',
        },
        {
          value: 'cesiwu',
          text: '车45度',
        },
        {
          value: 'front view',
          text: '车正面',
        },
        {
          value: 'from side',
          text: '车全侧',
        },
      ],
      statusList,
      processClassList,
      columns: [
        {
          title: '任务名称',
          width: 200,
          scopedSlots: { customRender: 'taskId' },
        },
        {
          title: '分类',
          key: 'taskType',
          width: 120,
          scopedSlots: { customRender: 'taskType' },
        },
        {
          title: '状态',
          width: 100,
          key: 'taskStatus',
          scopedSlots: { customRender: 'taskStatus' },
        },
        {
          title: '创建时间',
          dataIndex: 'ctime',
          width: 150,
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          title: '完成时间',
          dataIndex: 'taskExecuteEndTime',
          width: 150,
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          title: '创建人',
          dataIndex: 'creator',
          width: 100,
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          title: '操作',
          width: 220,
          scopedSlots: { customRender: 'operation' },
        },
      ],
      searchForm: {},
      pagination: {
        current: 1,
        pageSize: 30,
        total: 1,
      },
      dataList: [],
      tableLoading: false,
      searchLoading: false,
      taskForm: {},
      sourceImgList: [],
      taskVisible: false,
      paramsVisible: false,
      paramsDetail: undefined,
      imgLoading: false,
      tabKey: 1,
      picCreatorList: [],
      videoCreatorList: [],
    };
  },
  computed: {},
  async created() {
    if (this.$route.query.type && this.$route.query.type === 'video') {
      this.tabKey = 2;
    } else {
      this.searchForm.creator = this.$router.app.user.username || undefined;
      this.getDataList();
    }
    this.getPicCreatorList();
  },
  methods: {
    async getPicCreatorList() {
      const res = await api.getAiImageCreatorList();
      if (res.code === 200) {
        this.picCreatorList = res.data;
      }
    },
    getDataList(init = false) {
      if (init) this.pagination.current = 1;
      const { current: page, pageSize: size } = this.pagination;
      this.tableLoading = true;
      let params = {
        taskStatusList: this.searchForm.taskStatusList
          ? [this.searchForm.taskStatusList]
          : ['RUNNING', 'SUCCESS', 'FAILED'],
        taskTypeList: this.searchForm.taskTypeList ? [this.searchForm.taskTypeList] : undefined,
      };
      const getParams = {
        ...this.searchForm,
        ...params,
        page,
        size,
      };
      api
        .getAiImageTaskLogList(getParams)
        .then((res) => {
          if (res.code === 200) {
            this.dataList = res.data.list;
            this.pagination.total = res.data.total;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleTabChange(value) {
      if (value === 1) {
        this.searchForm = {
          creator: this.$router.app.user.username || undefined,
        };
        this.getDataList(true);
      }
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    getImgTaskDetail(id) {
      this.taskVisible = true;
      this.searchLoading = true;
      this.sourceImgList = [];
      this.taskForm = {};
      api
        .getTaskDetailById(id)
        .then((res) => {
          let _data = res.data;
          if (res.code == 200) {
            this.taskForm = _data;
            this.taskForm.angleText = this.getCarAngleText(_data.carAnglePrompt);

            let img = _data.subjectType === 'TEXT2IMAGE_CAR' ? _data.renderImage : _data.sourceImage;
            this.sourceImgList = [
              {
                uid: 2,
                name: 'source',
                status: 'done',
                url: img,
                thumbUrl: img,
                editor: true,
              },
            ];
          } else {
            this.$message.error(`获取任务详情失败,${res.message}`);
          }
        })
        .finally(() => {
          this.searchLoading = false;
        });
    },
    getParamsDetail(id) {
      this.paramsVisible = true;
      this.paramsDetail = {};
      this.searchLoading = true;
      api
        .getTaskDetailById(id)
        .then(({ code, data, message }) => {
          if (code === 200) {
            this.paramsDetail = data.aiImageResponse ? data.aiImageResponse : {};
          } else {
            this.$message.error(`获取参数失败,${message}`);
          }
        })
        .finally(() => {
          this.searchLoading = false;
        });
    },
    checkTaskImg(taskId) {
      if (this.imgLoading) return this.$message.info('请稍后');
      const hide = this.$message.loading('查询中...', 0);
      this.imgLoading = true;
      api
        .getOutputListByTaskIdApi({ taskId })
        .then((res) => {
          if (res.code === 200) {
            const imgList = res.data.map((v) => v.imageUrl);
            if (imgList.length) {
              const $viewer = this.$viewerApi({
                options: {
                  toolbar: true,
                  initialViewIndex: 0,
                  viewed(e) {},
                },
                images: imgList,
              });
            } else {
              this.$message.info('未生成图片');
            }
          }
        })
        .finally(() => {
          hide();
          this.imgLoading = false;
        });
    },
    getSubjectType(type) {
      const returnName = this.processClassList.find((item) => item.value === type);
      return returnName ? returnName.label : '-';
    },
    downloadJson(content) {
      if (content) {
        const filename = `${Date.now().toString()}_data.json`;
        const eleLink = document.createElement('a');
        eleLink.download = filename;
        eleLink.style.display = 'none';
        const blob = new Blob([JSON.stringify(content)]);
        eleLink.href = URL.createObjectURL(blob);
        document.body.appendChild(eleLink);
        eleLink.click();
        document.body.removeChild(eleLink);
      }
    },
    getCarAngleText(text) {
      const a = this.angleList.find((v) => v.value === text);
      return a ? a.text : text;
    },
  },
};
</script>

<style lang="less" scoped>
.drawer_class {
  ::v-deep .ant-form-item {
    margin-bottom: 3px;
  }
}
</style>
